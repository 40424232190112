.userViewVerticalActionsButtons {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  align-items: center;
  justify-content: space-between;
  font-size: 23px;
  border-left: 1px solid gray;
  padding: 15px 0;

  @media screen and (max-width: 400px) {
    font-size: 17px;
    padding: 27px 0;
  }

  &__hamburgerMenuIconWrapper {
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 10px;
  }

  &__otherIconsWrapper {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .userViewActionButton {
      margin-bottom: 30px;
    }

    @media screen and (max-width: 500px) {
      height: 25%;

      .userViewActionButton {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        text-align: center;
        line-height: 35px !important;
        font-size: 15px;
      }
    }
  }

  &__iconBadge {
    font-size: 20px;
    margin-bottom: 0 !important;

    @media screen and (max-width: 500px) {
      font-size: 15px;
    }


    .ant-badge-count {
      background: @primary-color;
      position: absolute !important;
      background: @scheduleCreate-form-delete;
      right: 8px !important;
      top: 5px !important;

      @media screen and (max-width: 500px) {
        background: #038fde;
        right: 4px !important;
        position: absolute !important;
        top: -5px;
      }
    }
  }
}
