.participantChatWrapper {
  height: 100%;

  .gx-chat-list-scroll {
    height: calc(100% - 38px) !important;
  }

  .participantSendMessageIcon {
    border-radius: 50%;
    background-color: #fff;
    font-size: 20px;
    line-height: -30px;
    display: flex;
    justify-content: center;
    margin-left: 5px;

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 20px;
      height: 30px;
    }
  }

  .inputRow {
    background-color: black;
    padding: 4px;
  }

  &_textArea {
    height: 30.9969px !important;
    min-height: 30.9969px !important;
    max-height: 30.9969px !important;
    overflow-y: hidden !important;
    border-radius: 25px;
  }
}