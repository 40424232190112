.tableWrapper {
  .ant-spin-nested-loading > div > .ant-spin {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /*
  Override the default css from 'ant-design' only for our custom React component'.
  */
  > .ant-spin-nested-loading {
    > .ant-spin-container {
      > .ant-table {
        > .ant-table-container {
          > .ant-table-content {
            overflow: auto !important;
            // max-height: 700px;
          }
        }
      }
    }
  }
}

.tableWrapper_chatOpened {
  > .ant-spin-nested-loading {
    > .ant-spin-container {
      > .ant-table {
        > .ant-table-container {
          > .ant-table-content {
            > table {
              min-width: auto !important;
            }
          }
        }
      }
    }
  }
}

.tableWrapper_chatOpened {
  .ant-table-expanded-row-fixed{
    max-width: 1140px;
  }
}

.participantsTableAndChat {

  &__flex {
    display: flex;
    position: relative;
  }

  &__block {
    display: block;
    position: relative;
  }

}
