@import '../../../variables';

.userViewChat {
  height: 100%;
  background: white;

  &__chat {
    bottom: 2rem;
    height: calc(100% - 30px);
    margin-bottom: 60px;

    @media screen and (min-width: (@screen-xl-min - 210px)) {
      margin-bottom: 0;
    }
  }

  .gx-chat-main-footer {
    justify-content: center;
  }

  &__input {
    bottom: 0;
    position: fixed;
    background: black;
    height: 50px;
    padding: 6px 5px !important;

    .ant-input {
      border-radius: 25px !important;
    }

    &--desktop {
      position: absolute;
      width: 100%;
    }
  }

  &__sendMessageButtonWrapper {

    background: white;
    border-radius: 50%;
    width: 31px;
    height: 31px;
    line-height: 31px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4px;
    margin-left: auto;

    &:hover {
      background-color: #3d3d3d;
      cursor: pointer;
    }

    &--disabled{
      pointer-events: none;
    }
  }

  &__textarea {
    height: 30.9974px !important;
    min-height: 30.9974px !important;
    max-height: 30.9974px !important;
    line-height: 1 !important;
  }

  &__sendMessageIcon {
    width: 20px;
    height: 20px;
  }

  .gx-chat-list-scroll {
    &--mobile {
      height: 49vh !important;

      & > div:first-child {
        .gx-chat-main-content {
          @media only screen and (max-height: 1400px) {
            margin-bottom: 10px;
          }
          @media only screen and (max-height: 1200px) {
            margin-bottom: 20px;
          }
           @media only screen and (max-height: 1000px) {
            margin-bottom: 30px;
            padding-bottom: 130px;
          }
          @media only screen and (max-height: 800px) {
            margin-bottom: 40px;
          }
          @media only screen and (max-height: 700px) {
            margin-bottom: 50px;
          }
          @media only screen and (max-height: 500px) {
            margin-bottom: 70px;
          }
        }
      }
    }

    &--desktop {
      & > div:first-child {
        margin-bottom: 0px !important;
        overflow-x: hidden !important;
        overflow-y: auto !important;
      }
    }
  }
  /**
  Used to fix the bug where the last message is "hidden" under the text area
   */
  &__conversationWrapper {
    position: absolute;
    height: 43vh !important;

    &:after {
      position: absolute;
      content: "";
      bottom: -35px;
      height: 35px;
      width: 1px;
    }
  }

  &__desktopButtonsWrapper {
    display: flex;
  }

  &__desktopButtons {
    padding: 10px 20px;
    max-height: 70px;
    min-height: 70px;
    background-color: #0c0c0c;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-left: 1px solid #3d3d3dcf;
  }

  &__desktopIcons {
    font-size: x-large;
    cursor: pointer;
  }

  &__chatHeading {
    padding: 10px;
    font-weight: bold;
    background-color: rgb(194, 194, 194);
    color: #0c0c0c;
  }

  &--mobile {
    display: none;
  }
}
