@import '../../../variables';

.tableLegend {
  font-size: 10px;
  padding-top: 20px;

  &__block {
    width: 15px;
    height: 10px;

    &--online {
      background-color: @participants-table-online;
    }

    &--offline {
      background-color: @participants-table-offline;
    }

    &--onlineRaised {
      background-color: @participants-table-onlineRaised;
    }

    &--signedOnLanding {
      background-color: @participants-table-signedOnLanding;
    }

    &--unknown {
      background-color: @participants-table-unknown;
    }
  }

  @media screen and (max-width: 650px) {
    &.ant-row {
      margin-top: -25px !important;
    }
  }
}
