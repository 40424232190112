@import "../../../variables";

.tableRow {
  & > * {
    background: none !important;
  }

  &__icon {
    font-size: 18px;
    color: @participants-table-icon;
    border-radius: 0;
  }

  &__checkbox {
    width: 18px;
    height: 18px;

    > span > span {
      border: 1px solid @participants-table-icon;
      width: 18px;
      height: 18px;
    }
  }

  &__name {
    font-size: 16px;
  }

  &__column {
    border-bottom: 5px solid white !important;
    text-align: center;

    &--connStatus {
      background-color: white !important;
    }

    &__last {
      border-bottom: 5px solid white !important;
      text-align: center;
      padding: 0 !important;
    }
  }

  &--online {
    background-color: @participants-table-online;
  }

  &--offline {
    background-color: @participants-table-offline;
  }

  &--onlineRaised {
    background-color: @participants-table-onlineRaised;
  }

  &--signed {
    background-color: @participants-table-signedOnLanding;
  }

  &--unknown {
    background-color: @participants-table-unknown;
  }

  &__chatButton,
  &__chatButton:hover,
  &__chatButton:active,
  &__chatButton:focus,
  &__chatButton:disabled,
  &__chatButton:disabled:hover {
    width: 100%;
    margin-bottom: 0;
    background-color: inherit;
    border: 0;
  }

  &__chatButton:last-child {
    margin-bottom: 0;
    overflow-y: hidden;
  }
}

.switchWrapper {
  > span {
    padding-right: 5px;
  }
}

