.language-filter-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.gx-chat-sidenav-main {
  height: 100%;
  .gx-chat-sidenav-content {
    height: 100%;
    .gx-chat-sidenav-scroll-tab {
      height: calc(100vh - 334px) !important;
    }
  }
}

.gx-chat-info {
  max-width: calc(100% - 140px) !important;
}


.gx-chat-user-row {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  padding: 16px;
  width: 100%;
  margin: 0 !important;
}

.gx-chat-user-row:hover, .predefined-messages:hover {
  background-color: #eee;
  transition: background-color 0.3s linear;
  .predefined-messages-button {
    background-color: inherit;
    transition: background-color 0.3s linear;
  }
}

.gx-chat-user-item {
  padding: 0 !important;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  width: 100%;
}

.gx-chat-list-scroll {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.gx-chat-textarea {
  border-radius: 6px !important;
  border: 1px solid rgb(217, 217, 217) !important;
  resize: none;
}


.scrollable-chat {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: scroll;
  margin-right: -15px;
  margin-bottom: -15px;
}

.gx-chat-main, .gx-chat-box {
  height: calc(100vh - 252px) !important;
}

.chat-sidebar {
  height: calc(100vh - 305px);
}

.buttons-with-opened-chat {
  left: calc(260px + 2rem);
}

.jitsi-chat {
  width: 260px;
  position: absolute;
  bottom: 2rem;
  background-color: #fff;
  height: calc(100% - 30px);
  &-input {
    bottom: 0;
    position: absolute;
  }
}

.jitsi-video-with-chat {
  margin-left: 260px;
  width: calc(90% - 260px);
}

.chat-language-filter {
  display: flex;
  align-items: center;
}

.received-message-cell {
  color: #fff;
  background-color: #f50537 !important;
  border-radius: 20px !important;

  @media only screen and (max-height: 600px) {
    padding: 8px 15px !important;
  }
}

.sent-message-cell {
  color: #fff;
  background-color: #4c4c4c !important;
  border-radius: 10px !important;
}

.tag-button {
  border: 0;
  background-color: inherit;
  margin: 0;

  &:active, &:hover, &:focus {
    color: #4e4e4e;
    border: 0;
    border-color: none;
    background-color: inherit;
    margin: 0;
  }
}

.moderator-logo  {
  border: 1px solid black;
  margin-bottom: auto;

  img {
    display: initial !important;
    height: auto !important;
    width: 30px !important;
  }
}

.user-avatar {
  color: black;
  border: 1px solid black;
  background-color: inherit;
  margin-bottom: auto;
}

.user-multimedia-icon {
  height: 35px;
  width: 35px;
}
