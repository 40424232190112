.menu-item-link {
  color: #fff !important;
  transition: 0.2s ease 0s;
}

.menu-item-link-selected {
  color: #d2831b !important;
  transition: 0.2s ease 0s;
}
create.tableMarketing
.ant-menu-horizontal {
  border-bottom: 0 !important;
}

.ant-menu-submenu {
  margin-left: 0 !important;
}

.schedule-details-col {
  padding: 0 !important;
}

.menu-item-label {
  color: #fff;
}

.slot-menu-item-ellipsis span{
  width: 120px;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.event-menu-item-ellipsis span{
  width: 120px; 
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.gx-header-notifications {
  margin-left: 15px !important;
}

.gx-header-horizontal-main-flex {
  justify-content: space-between;
}

.flex-container {
  display: flex;

  &--visitor {
    &--desktop {
      flex: 1;
    }

    justify-content: space-between;

    .gx-header-horizontal-nav.gx-header-horizontal-nav-curve {
      flex: 1;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .gx-header-horizontal-nav.gx-header-horizontal-nav-curve.gx-d-none {
      display: none !important;
    }

  }
}

.audi-table {
  margin-bottom: 5rem;
  border-radius: 6px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}

.import_document {
  border: 0;
}

.ant-modal-footer > .ant-btn {
  padding: 0 15px;
}

.ant-table-tbody > tr > td {
  padding: 8px 16px;
}

.schedule-header-btn {
  padding: 0 10px;
}

.predefined-messages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  &-button {
    border: 0;
    margin-bottom: 0;
    background-color: #fff;
  }
  &-input {
    border: 0;
    background-color: inherit;
  }
  &-input:focus {
    background-color: #fff;
  }
}

.popover-button {
  padding-left: 10px;
  padding-right: 10px;
  &:first-child {
    margin-right: 10px;
  }
}

.popover-content {
  display: flex;
  justify-content: flex-end;
}

.predefined-messages-wrapper {
  padding: 0;
  .gx-module-content-scroll {
    min-width: 315px;
    height: 100% !important;
  }
}

.tag-clicked {
  color: #5aa6f1;
}

.tag-unclicked {
  color: #4e4e4e;
}

.user-menu-button {
  background-color: inherit;
  border: 0;
  color: #666;
  margin-bottom: 0 !important;

  &:hover, &:focus, &:active {
    background-color: inherit;
  }

}

/**
Setting some margin on the last element on the 'visitor' menu,
so there is a space with the languages menu.

Note: using "nth-last-child(2)" because the library is setting one more element at the end
*/
.user-menu-item {
  &:nth-last-child(2) {
    margin-right: 70px !important;
  }
}

.all-users-checkbox {
  padding: 10px;
}

.users-list-wrapper > .gx-module-content-scroll {
  height: 100% !important;
}

.all-users {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  &-btn {
    border: 0;
    margin-left: 20px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
  }
}

.multimedia-status {
  margin-left: auto;
}

.gx-bubble {
  display: flex !important;
  justify-content: space-between;
}

/*
Range picker button "OK" text to be centered
*/
.ant-picker-footer {
  & > ul > li > .ant-btn-sm {
    line-height: 20px;
  }
}

.video-container {
  position: relative;
}

.video-buttons-container {
  position: absolute;
  bottom: 2.5rem;
  left: 2rem;
}

.video-button {
  background-color: inherit;
  border: 0;
  padding: 3px;
  &:active, &:hover, &:focus {
    background-color: rgba(66, 66, 66, 0.5);
  }
}

.user-jitsi-icons {
  color: #fff;
  font-size: xx-large;
  &-raised-hand {
    color: #68E;
    font-size: xx-large;
  }
}

.jitsi-video {
  margin-bottom: 30px;
}

/**
used to show the line breaks in the chat messages
and break long string on new line
 */
.gx-message {
  white-space: pre-wrap !important;
  word-break: break-word;
}

.moderator-languages-menu {
  &-item {
    display: flex;
    align-items: center;
  }
}

.languages-moderator-popover {
  .ant-menu {
    width: 100%;
  }
}

.visitor-header {
  background-color: #fff;

  &--desktop {
    > div.gx-header-horizontal-top {
      padding: 0 !important;
    }
  }
}

.gx-main-content-wrapper--user {
  display: flex !important;
}

.login-logo {
  width: 100px;
}

.switch-media-type{
  display: flex;
  flex-direction: row;
  align-items: center;
  
  & h5{
    margin: 0;
    line-height: 13px;
  }

  & .ant-switch {
    background-color: #038fde !important;
    margin: 0 10px;
  }
}