@import '../../../variables';

.userViewMobileActionsButtons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  color: white;
  background-color: black;

  &--desktop {
    display: none;
  }
}
