.ant-row {
  margin: 0 !important;
}


/*
Override the default css from 'ant-design' only for our custom React component
with CSS module file 'DataTable'.

Explanation:
It will target only element starting with 'DataTable_DataTable__'
 */
&[class*='DataTable_DataTable__'] {
  > .ant-spin-nested-loading {
    > .ant-spin-container {
      > .ant-table {
        > .ant-table-container {
          > .ant-table-content {
            overflow: auto !important;
            max-height: 700px;
          }
        }
      }
    }
  }
}
