@import '../../../variables';

.userView {
  display: flex;
  flex: 1;

  &--mobile {
    &--fullscreen {
      height: 100vh;

      & .ant-row {
        justify-content: space-around;
        background-color: #000;
      }
    }
  }

  &__topbar {
    height: @userView-topBar-height;
    min-height: @userView-topBar-height;
    padding-right: 20px;

    .ant-badge {
      margin-bottom: 0 !important;
    }
  }

  &__topbarIconsWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__videoAndActionsWrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &--mobile {
      height: 45%;
      &--fullscreen {
        flex: 1;
        height: 100%;
      }

      height: 60%;
      background-color: #000;

      & video{
        height: 84%;
      }
    }

    &--desktop {
      background: black;
      flex: 1;
    }
  }

  &__title {
    padding: 10px 15px;
    color: white;
    background-color: #000000;
    min-height: 45px;
    max-height: 45px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;

    & > p {
      margin: 0;
    }

    &--desktop {
      min-height: 70px;
      max-height: 70px;
      padding-left: 50px;
    }
  }

  &__chatComponentWrapper {

    &--mobile {
      height: 40%;

      & > div{
        overflow: hidden;
      }
    }

    &--desktop {
      height: 100%;
    }
  }

  &--desktop {

    overflow: hidden;
  }

  &-visitorLanguageMenu {
    display: flex;
    align-items: center;
  }

  &__userKickedPlaceholder {
    width: 100%;
    height:100%;
    background-color: black;
  }


}
