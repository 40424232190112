.micCamActionsButtons {
  position: absolute;
  width: 35%;
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  border-radius: 25px;
  bottom: 10px;
  padding: 7px 7px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);

  @media screen and (min-width: 450px) {
    width: 35%;
  }
  @media screen and (min-width: 600px) {
    width: 25%;
  }
  @media screen and (min-width: 750px) {
    width: 20%;
  }
  @media screen and (min-width: 1000px) {
    width: 12%;
  }
  @media screen and (min-width: 1100px) {
    width: 10%;
  }


  &__icon {
    padding: 5px;
    width: 35px !important;
  }
}

.fadeIn {
  transition: 2s;
};

.fadeOut {
  position: absolute;
  bottom: -50px;
  opacity: 0;
  transition: bottom 2s, opacity 2s;
}
