@header-margin: 0;
@header-width: 100%;

.hamburger-menu {

  &-moderator {
    .ant-drawer-content, .ant-drawer-header, .ant-menu {
      background-color: #003366;
    }
    .ant-drawer-title {
      color: #fff;
    }
  }

  &-visitor {
    .ant-drawer-title {
      color: #333;
    }
  }
}

.hamburger-icon {
  &-visitor {
    color: black;
  }

  &-moderator {
    color: #fff;
  }
}

.visitorLogoWrapper {
  text-align: center;

  &--mobile {
    flex: 1;
  }
  &--desktop {
    flex: none;
  }

  .gx-logo {
    width: 100px;
    margin-right: 0 !important;
  }
}


.header-container {

  &-visitor {
    margin-left: @header-margin;
    margin-right: @header-margin;
    width: @header-width;
    padding: 0 50px;

    &--mobile {
      padding: 0 10px 10px 10px;
    }
  }

  &-moderator {
    margin-left: @header-margin;
    margin-right: @header-margin;
    width: @header-width;
    padding: 0;
  }
}

.main-header-wrapper {
  padding: 0;
}

.gx-header-horizontal-main {
  width: 100%;
}

.gx-container {
  &-visitor {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

.stage-translator-language {
  margin-left: 20px;
  color: black;
  background-color: #fff;
}