.loginForm {
  .ant-row.ant-form-item:not(:last-child) {
    margin-bottom: 20px !important;
  }

  .ant-form-item {
    min-height: 85px;
  }

  &__submit {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__buttonsWrapper {
    display: flex;
    justify-content: space-between;
  }
}
