.userViewSlotLanguagesMenu {
  display: flex;
  justify-content: center;
  align-items: center;

  &__languageCircle {
    font-size: 17px;
    font-weight: 700;
    margin: 0 10px 0 0 !important;
    background: black;
    width: 26px;
    height: 26px;
    line-height: 26px;
  }

  &__languageCircleNotSelected {
    font-size: 17px;
    font-weight: 700;
    background-color: #fff;
    color: black;
    width: 26px;
    height: 26px;
    line-height: 26px;
    border: 1px solid black;
    cursor: pointer;
  }

  &__dropdown {
    font-size: 25px;
    color: black;
  }

  &__languageIsoCode {
    margin-left: 10px;
    border: 1px solid;
    background-color: inherit;
    color: #333;

    .ant-avatar-string {
      transform: scale(0.8) translateX(-50%) !important;
    }
  }

  &__languageMenuItem {
    display: flex;
    justify-content: flex-end;;
  }
}
