@import '../../../variables';

.actionsWrapper {
  padding: 0;

  &--bottom {
    padding-top: 10px;
  }

  &__actionsSubRow {
    align-items: center;

    > div.ant-col:first-child {
      padding-left: 0 !important;
    }

    > div.ant-col:last-child {
      padding-right: 0 !important;
    }
  }

  &__icon {
    font-size: 21px;
  }

  &__button {
    padding: 0 16px;
    margin-bottom: 0;
  }

  &__menuItemActive {
    background-color: @participants-actionsTop-menuItemActive;
  }
}

.addUserModalWrapper {
  &__form {
    .ant-row.ant-form-item:not(:last-child) {
      margin-bottom: 20px !important;
    }
    .ant-form-item {
      min-height: 85px;
    }
  }
}
