/**
Override ant styles to make the 'X' button on the menu to be on same line as text also to be on the most left side
 */
.userViewMenuDrawer {
  .ant-drawer-content-wrapper {
    padding: 0 !important;

    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          left: 0 !important;
          border-bottom: 1px solid black !important;
          border-radius: 0 !important;

          .ant-drawer-title {
            padding-left: 26px;
            line-height: 27px;
          }
          .ant-drawer-close {
            top: 0 !important;
            left: 0 !important;
            font-size: 18px;
          }
        }
      }
    }
  }

  &--ios,
  &--safari {
    .ant-drawer-content-wrapper {
      padding: 0 !important;

      .ant-drawer-content {
        .ant-drawer-wrapper-body {
          .ant-drawer-header {
            left: 0 !important;
            border-bottom: 1px solid black !important;
            border-radius: 0 !important;

            .ant-drawer-title {
              padding-left: 26px;
              line-height: 27px;
            }

            .ant-drawer-close {
              top: 0 !important;
              left: -320px !important;
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  &--fullscreen {
    .ant-drawer-content-wrapper {
      padding: 0 !important;

      .ant-drawer-content {
        .ant-drawer-wrapper-body {
          .ant-drawer-header {
            border-bottom: 1px solid black !important;
            border-radius: 0 !important;

            .ant-drawer-title {
              padding-right: 26px;
              line-height: 27px;
              text-align: right;
            }
            .ant-drawer-close {
              top: 0 !important;
              right: 0 !important;
              left: unset !important;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

.userViewMenu {
  padding: 10px 0 !important;
  border: none !important;
}

/**
Override ant styles to NOT have active/selected menu item background color;
 */
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.userViewMenuItem  {
  background-color: white !important;
}

.userViewMenuItem {

  &__button {
    border: none !important;
    box-shadow: none !important;
    background: none !important;
    margin-bottom: 0 !important;
    font-size: 16px;
    margin-left: -18px;
  }
}
