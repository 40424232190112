@import '../../../variables';

.userViewActionButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  cursor: pointer;

  &--selected {
    background-color: white;
  }

  &--desktop {
    margin-right: 30px;

    &--selected {
      background-color: white;
      color: black;
    }
  }

  &--mobile {
    &--selected {
      background-color: white;
    }
  }

  &__iconBadge {
    margin-bottom: 0 !important;

    .userViewActionButton {
      margin: 0;
    }

    .ant-badge-count {
      background: @scheduleCreate-form-delete;
      right: 8px !important;
      top: 5px !important;
      position: absolute !important;
      box-shadow: none;
    }
  }
}
