.participantConnStatus {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &--active {
     background-color: green;
  }

  &--inactive {
    background-color: gray;
  }

  &--unknown {
    background-color: #656060;
  }

  &--interrupted {
    background-color: red;
  }

  &--restoring {
    background-color: deepskyblue;
  }

  &__content {
    color: white;
  }
}
