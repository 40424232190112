.schedule-createForm {
  padding-bottom: 32px;

  & > div.ant-row.ant-form-item:first-child {
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    margin-bottom: 30px !important;

    & > .ant-form-item-control {
      text-align: center;
      width: 30%;
    }
  }

  &__groupDate {

  }
  &__submitBtn {
    padding: 0 10px;
    margin-bottom: 0;
    margin-top: 20px;
    display: block;
    margin-left: auto;
  }

  &__btn {
    line-height: 26px !important;
    margin-bottom: 0 !important;
    margin-left: auto;
    font-size: 10px !important;
    color: white !important;
    display: block;

    &--green {
      background: @scheduleCreate-form-add !important;
      margin-right: 0 !important;

      &:hover {
        border-color: @scheduleCreate-form-add !important;
      }
    }

    &--red {
      background: @scheduleCreate-form-delete !important;

      &:hover {
        border-color: @scheduleCreate-form-delete !important;
      }
    }
  }
}
