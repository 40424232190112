@import '../../../../variables';

.eventUsersTable {
  width: 100%;
  margin-top: 10px;

  &__column {
    padding: 4px 8px !important;
    width: 17%;

    > .ant-row.ant-form-item {
      align-items: center;
    }

    &:last-child {
      text-align: center;
    }

  }

  &__rowActionBtn {
    line-height: 23px !important;
    margin-bottom: 0 !important;
    font-size: 10px !important;
    color: white !important;

    &--green {
      background: @scheduleCreate-form-add !important;

      &:hover {
        border-color: @scheduleCreate-form-add !important;
      }
    }

    &--red {
      background: @scheduleCreate-form-delete !important;

      &:hover {
        border-color: @scheduleCreate-form-delete !important;
      }
    }
  }

  &__languages-select {
    width: 100%;
  }
}
