.userViewVideoTracks {
  position: relative;


  &--mobile {
    flex: 1;

    &--fullscreen {
      max-height: 100%;
      height: 100%;
    }

  }

  &--desktop {
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(61, 61, 61, 0.81);
    border-bottom: 1px solid rgba(61, 61, 61, 0.81);
    position: relative;

    &--fullscreen {
      height: 100%;
    }
  }

  &__mainVideoTag {
    display: block;
    position: absolute;
    background: black;

    &--mobile {
      height: 100%;

     /* &--ios,
      &--safari {
        max-height: 170px;
      }*/

      &--fullscreen {
        max-height: 100%;
      }
    }

    &--desktop {
      position: absolute;
      height: 100%;
      border: none;
    }
  }

  &__myVideoTag {

    &--desktop {
      width: 136px;
      height: 100px;
      z-index: 99999;
      position: absolute;
      bottom: 0;
      right: 0;

      &--dnone {
        display: none;
      }
    }

    &--mobile {
      width: 120px;
      height: 72px;
      // z-index: 99999;
      position: absolute;
      bottom: 0;
      right: 0;

      &--dnone {
        display: none;
      }
    }
  }


  &__videoFullScreenIcon {
    position: absolute;
    top: 12px;
    right: 12px;
    color: white;
    font-size: 18px;
    cursor: pointer;

    &--fullscreen {
      top: 35px;
    }
  }

  &__videoPlayIcon {
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    z-index: 100;
  }

  &__presenterVideoMuted {
    background: black !important;

    &--dnone {
      display: none;
    }

    &--dflex {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > h3 {
      color: white !important;
      margin: 0;
    }
  }

  &__speakerVideoTagWrapper {
    &--dnone {
      display: none;
    }

    &--desktop {
      width: 136px;
      height: 100px;
      z-index: 99999;
      position: absolute;
      bottom: 0;
      left: 0;
      border: 1px solid gray;
    }

    &--mobile {
      width: 120px;
      height: 72px;
      // z-index: 99999;
      position: absolute;
      bottom: 0;
      left: 0;
      border: 1px solid gray;
    }
  }

  &__speakerVideoTag {
    width: 100%;
    height: 100%;
    z-index: 99999;
  }

  &__visitorSpeakerVideoMuted {
    &--dnone {
      display: none;
    }

    &--dflex {
      display: flex;
      justify-content: center;
      align-items: center;
      background: black;
      height: 100%;

      & > h3 {
        color: white !important;
        margin: 0;
      }
    }
  }
}

