.presenterVideoMuted {
    background: black;
    width: 70%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.translatorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
