.eventAndGroupSelectionWrapper {
  text-align: center;

  &__spinner {
    padding-top: 100px;
  }

  &__selects {
    display: flex;
    justify-content: space-between;
    width: 60%;
    padding-top: 30px;
    margin: 0 auto;
  }

  &__select-block {
    display: flex;
    flex-direction: column;
    width: 40%;

    > span {
      margin-bottom: 15px;
    }
  }
}
