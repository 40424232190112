@import '../../../variables';

.userViewNoPresenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 100px);
  min-height: 150px;
  color: @white-color;
  background-color: #181818;
  font-size: 50px;
  position: relative;

  @media only screen and (max-height: 630px) {
    min-height: 50px;
    font-size: 20px;
  }

  &--fullscreen {
    height: 100%;
  }
}
